import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    Message,
    Modal,
    Breadcrumb,
    Card,
    Grid,
    Form,
    Space,
    Button,
    Divider,
    Table,
    Pagination,
    Input,
    Dropdown,
    Menu,
    Select,
    Image,
    Tag,
} from "@arco-design/web-react";
import {IconSearch, IconUndo, IconPlusCircle, IconEye, IconDelete, IconUpload, IconDownload, IconSync} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Import from "../../../../../nowui-common/component/import";
import Process from "../../../../../nowui-common/component/process";
import Loading from "../../../../../nowui-common/component/loading";
import Selected from "../../../../../nowui-common/component/selected";
import Sort from "../../../../../nowui-common/component/sort";

import PlanDetail from "./detail";

const PlanIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const columnListRef = useRef([]);
    const [columnList, setColumnList] = useState([]);
    const [operationColumn, setOperationColumn] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [processType, setProcessType] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [action, setAction] = useState("");
    const [planId, setPlanId] = useState("");
    const [planCount, setPlanCount] = useState(0);
    const [planList, setPlanList] = useState([]);
    const [planPageIndex, setPlanPageIndex] = useState(1);
    const [planPageSize, setPlanPageSize] = useState(10);
    const [sortList, setSortList] = useState([]);

    useEffect(() => {
        handleSearchPlan();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/plan/index");
        setBreadcrumbList(breadcrumbList);

        let columnList = [
            {
                width: 150,
                dataIndex: "planImage",
                title: "计划二维码",
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.planImage ? <Image width={50} src={process.env.FILE_URL + record.planImage} alt="" /> : null}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "planStatus",
                title: outletContext.intl.formatMessage({id: "plan.planStatus"}),
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.planStatus ? (
                            <Tag color="#00b42a" size="medium">
                                {outletContext.intl.formatMessage({id: "global.on"})}
                            </Tag>
                        ) : (
                            <Tag color="#f53f3f" size="medium">
                                {outletContext.intl.formatMessage({id: "global.off"})}
                            </Tag>
                        )}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "planName",
                title: outletContext.intl.formatMessage({id: "plan.planName"}),
            },
            {
                dataIndex: "planLocation",
                title: outletContext.intl.formatMessage({id: "plan.planLocation"}),
            },
            {
                dataIndex: "createTime",
                title: outletContext.intl.formatMessage({id: "global.createTime"}),
            },
        ];
        setColumnList(columnList);

        setOperationColumn({
            width: 110,
            fixed: "right",
            dataIndex: "operation",
            title: outletContext.intl.formatMessage({id: "global.operation"}),
            render: (col, record, index) => (
                <React.Fragment>
                    <Button
                        type="default"
                        icon={<IconEye />}
                        onClick={() => {
                            setIsDetail(true);
                            setAction("update");
                            setPlanId(record.planId);
                        }}
                    >
                        {outletContext.intl.formatMessage({id: "global.view"})}
                    </Button>
                </React.Fragment>
            ),
        });

        Util.changeSortList(sortList, setSortList, columnList);
    }, [localStorage.getItem("locale")]);

    useEffect(() => {
        onSelect(selectedList);
    }, [selectedList]);

    useEffect(() => {
        columnListRef.current = columnList;
    }, [columnList]);

    useEffect(() => {
        let columnList = columnListRef.current;
        if (columnList.length == 0) {
            return;
        }
        for (let i = 0; i < columnList.length; i++) {
            let direction = "";
            for (let j = 0; j < sortList.length; j++) {
                if (columnList[i].dataIndex == sortList[j].field) {
                    direction = sortList[j].direction;
                    break;
                }
            }
            columnList[i].sortOrder = direction;
        }
        setColumnList([...columnList]);

        handleSearchPlan();
    }, [sortList]);

    const handleSearchPlan = () => {
        handleLoadPlanList(1, planPageSize);
    };

    const handleLoadPlanList = (planPageIndex, planPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;
        data.pageIndex = planPageIndex;
        data.pageSize = planPageSize;

        Util.request(outletContext, {
            url: process.env.API_URL + "/plan/frontend/website/v1/list",
            controller: controller,
            data: data,
            success: (response) => {
                setPlanCount(response.count);
                setPlanList(response.data);
                setPlanPageIndex(planPageIndex);
                setPlanPageSize(planPageSize);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleDeletePlan = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: outletContext.intl.formatMessage({id: "global.deleteConfirmTitle"}),
            content: outletContext.intl.formatMessage({id: "global.deleteConfirmContent"}),
            okButtonProps: {
                status: "danger",
            },
            onOk: () => {
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/plan/frontend/website/v1/delete",
                    controller: controller,
                    data: {
                        planIdList: selectedIdList,
                    },
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        setSelectedIdList([]);
                        setSelectedList([]);

                        handleLoadPlanList(planPageIndex, planPageSize);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            },
        });
    };

    const handleExportPlanSearch = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;

        Util.request(outletContext, {
            url: process.env.API_URL + "/plan/frontend/website/v1/export/search",
            controller: controller,
            data: data,
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleExportPlanSelected = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/plan/frontend/website/v1/export/selected",
            controller: controller,
            data: {
                planIdList: selectedIdList,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleSyncPlan = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/plan/frontend/website/v1/sync",
            controller: controller,
            data: {
                planIdList: selectedIdList,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <Card className="card" title={outletContext.intl.formatMessage({id: "global.searchTable"})} bordered={false}>
                        <Grid.Row gutter={20}>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "plan.planName"})} field="planName">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchPlan();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "plan.planLocation"})} field="planLocation">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchPlan();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                        </Grid.Row>
                        <Space className="action" wrap>
                            <React.Fragment>
                                <Button
                                    type="primary"
                                    icon={<IconSearch />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSearchPlan();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.search"})}
                                </Button>
                                <Button
                                    icon={<IconUndo />}
                                    loading={isLoad}
                                    onClick={() => {
                                        form.resetFields();

                                        setSelectedIdList([]);
                                        setSelectedList([]);
                                        setSortList([]);
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.reset"})}
                                </Button>
                            </React.Fragment>
                            <Divider type="vertical" />
                            <Button
                                icon={<IconPlusCircle />}
                                onClick={() => {
                                    setIsDetail(true);
                                    setAction("save");
                                    setPlanId("");
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.save"})}
                            </Button>
                            <Button
                                icon={<IconDelete />}
                                disabled={selectedIdList.length == 0}
                                onClick={() => {
                                    handleDeletePlan();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.delete"})}
                            </Button>
                        </Space>
                        <Sort sortList={sortList} setSortList={setSortList} />
                        <Table
                            className="table"
                            columns={columnList.concat([operationColumn])}
                            rowKey="planId"
                            data={planList}
                            rowSelection={{
                                type: selectType,
                                columnWidth: 50,
                                selectedRowKeys: selectedIdList,
                                onChange: (selectedRowKeys) => {
                                    setSelectedIdList(Util.getSelectedIdList(selectedIdList, planList, "planId", selectedRowKeys));
                                    setSelectedList(Util.getSelectedList(selectedList, planList, "planId", selectedRowKeys));
                                },
                            }}
                            scroll={{x: 50 + columnList.length * 150 + operationColumn.width}}
                            border={false}
                            pagination={false}
                            onChange={(pagination, sorter, filters) => {
                                setSortList(Util.getSortList(sortList, sorter, columnList));
                            }}
                        />
                        <div className="pagination">
                            <div className="pagination_selected">
                                <React.Fragment>
                                    <Button
                                        onClick={() => {
                                            setIsSelected(true);
                                        }}
                                    >
                                        {outletContext.intl.formatMessage({id: "global.selected"})} {selectedIdList.length}
                                    </Button>
                                </React.Fragment>
                            </div>
                            {typeof planCount == "undefined" ? null : (
                                <Pagination
                                    current={planPageIndex}
                                    total={planCount}
                                    simple={main.isMobile}
                                    showTotal
                                    sizeCanChange={!main.isMobile}
                                    onChange={(pageIndex, pageSize) => {
                                        handleLoadPlanList(pageIndex, pageSize);
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                </Form>
                <div className="card_bottom"></div>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
            {isDetail ? (
                <PlanDetail
                    visible={isDetail}
                    action={action}
                    planId={planId}
                    onClose={() => {
                        setIsDetail(false);
                    }}
                    onSubmit={() => {
                        setIsDetail(false);

                        handleLoadPlanList(planPageIndex, planPageSize);
                    }}
                />
            ) : null}
            {isProcess ? (
                <Process
                    visible={isProcess}
                    apiUrl={process.env.API_URL}
                    processModule="plan"
                    processType={processType}
                    onClose={() => {
                        setIsProcess(false);
                    }}
                />
            ) : null}
            {isImport ? (
                <Import
                    visible={isImport}
                    name={outletContext.intl.formatMessage({id: "plan"})}
                    templateUrl={process.env.API_URL + "/plan/frontend/website/v1/export/template"}
                    uploadUrl={process.env.API_URL + "/plan/frontend/website/v1/upload/process"}
                    startUrl={process.env.API_URL + "/plan/frontend/website/v1/import/process"}
                    accept=".xls,.xlsx"
                    onClose={() => {
                        setIsImport(false);
                    }}
                    onHistory={() => {
                        setIsProcess(true);
                        setProcessType(0);
                    }}
                />
            ) : null}
            {isSelected ? (
                <Selected
                    visible={isSelected}
                    url={process.env.API_URL + "/plan/frontend/website/v1/list/selected"}
                    selectedIdListKey="planIdList"
                    selectedIdList={selectedIdList}
                    sortList={sortList}
                    rowKey="planId"
                    columnList={columnList}
                    onClose={() => {
                        setIsSelected(false);
                    }}
                    onRemove={(idList) => {
                        setSelectedIdList(Util.removeSelectedIdList(selectedIdList, idList));
                        setSelectedList(Util.removeSelectedList(selectedList, "planId", idList));
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

PlanIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(PlanIndex);
