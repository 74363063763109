import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    Message,
    Modal,
    Breadcrumb,
    Card,
    Grid,
    Form,
    Space,
    Button,
    Divider,
    Table,
    Pagination,
    Input,
    Dropdown,
    Menu,
    Select,
    Image,
    Tag,
} from "@arco-design/web-react";
import {IconSearch, IconUndo, IconPlusCircle, IconEye, IconDelete, IconUpload, IconDownload} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Import from "../../../../../nowui-common/component/import";
import Process from "../../../../../nowui-common/component/process";
import Loading from "../../../../../nowui-common/component/loading";
import Selected from "../../../../../nowui-common/component/selected";
import Sort from "../../../../../nowui-common/component/sort";

import CompanyUserDetail from "./detail";

const CompanyUserIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const columnListRef = useRef([]);
    const [columnList, setColumnList] = useState([]);
    const [operationColumn, setOperationColumn] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [processType, setProcessType] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [action, setAction] = useState("");
    const [userId, setUserId] = useState("");
    const [companyUserCount, setCompanyUserCount] = useState(0);
    const [companyUserList, setCompanyUserList] = useState([]);
    const [companyUserPageIndex, setCompanyUserPageIndex] = useState(1);
    const [companyUserPageSize, setCompanyUserPageSize] = useState(10);
    const [sortList, setSortList] = useState([]);

    useEffect(() => {
        handleSearchCompanyUser();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/company/user/index");
        setBreadcrumbList(breadcrumbList);

        let columnList = [
            {
                dataIndex: "userName",
                title: outletContext.intl.formatMessage({id: "companyUser.userName"}),
            },
            {
                dataIndex: "userPhone",
                title: outletContext.intl.formatMessage({id: "companyUser.userPhone"}),
            },
            {
                dataIndex: "userImage",
                title: outletContext.intl.formatMessage({id: "companyUser.userImage"}),
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.userImage ? <Image width={50} src={process.env.FILE_URL + record.userImage} alt="" /> : null}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "companyAdmin",
                title: outletContext.intl.formatMessage({id: "companyUser.companyAdmin"}),
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.companyAdmin ? (
                            <Tag color="#00b42a" size="medium">
                                {outletContext.intl.formatMessage({id: "global.yes"})}
                            </Tag>
                        ) : (
                            <Tag color="#f53f3f" size="medium">
                                {outletContext.intl.formatMessage({id: "global.no"})}
                            </Tag>
                        )}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "companyLeader",
                title: outletContext.intl.formatMessage({id: "companyUser.companyLeader"}),
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.companyLeader ? (
                            <Tag color="#00b42a" size="medium">
                                {outletContext.intl.formatMessage({id: "global.yes"})}
                            </Tag>
                        ) : (
                            <Tag color="#f53f3f" size="medium">
                                {outletContext.intl.formatMessage({id: "global.no"})}
                            </Tag>
                        )}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "userStatus",
                title: outletContext.intl.formatMessage({id: "user.userStatus"}),
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.userStatus == 0 ? (
                            <Tag color="#00b42a" size="medium">
                                {record.userStatusLabel}
                            </Tag>
                        ) : null}
                        {record.userStatus == 1 ? (
                            <Tag color="#f53f3f" size="medium">
                                {record.userStatusLabel}
                            </Tag>
                        ) : null}
                    </React.Fragment>
                ),
            },
            {
                width: 180,
                dataIndex: "createTime",
                title: outletContext.intl.formatMessage({id: "global.createTime"}),
            },
        ];
        setColumnList(columnList);

        setOperationColumn({
            width: 110,
            fixed: "right",
            dataIndex: "operation",
            title: outletContext.intl.formatMessage({id: "global.operation"}),
            render: (col, record, index) => (
                <React.Fragment>
                    <Button
                        type="default"
                        icon={<IconEye />}
                        onClick={() => {
                            setIsDetail(true);
                            setAction("update");
                            setUserId(record.userId);
                        }}
                    >
                        {outletContext.intl.formatMessage({id: "global.view"})}
                    </Button>
                </React.Fragment>
            ),
        });

        Util.changeSortList(sortList, setSortList, columnList);
    }, [localStorage.getItem("locale")]);

    useEffect(() => {
        onSelect(selectedList);
    }, [selectedList]);

    useEffect(() => {
        columnListRef.current = columnList;
    }, [columnList]);

    useEffect(() => {
        let columnList = columnListRef.current;
        if (columnList.length == 0) {
            return;
        }
        for (let i = 0; i < columnList.length; i++) {
            let direction = "";
            for (let j = 0; j < sortList.length; j++) {
                if (columnList[i].dataIndex == sortList[j].field) {
                    direction = sortList[j].direction;
                    break;
                }
            }
            columnList[i].sortOrder = direction;
        }
        setColumnList([...columnList]);

        handleSearchCompanyUser();
    }, [sortList]);

    const handleSearchCompanyUser = () => {
        handleLoadCompanyUserList(1, companyUserPageSize);
    };

    const handleLoadCompanyUserList = (companyUserPageIndex, companyUserPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;
        data.pageIndex = companyUserPageIndex;
        data.pageSize = companyUserPageSize;

        Util.request(outletContext, {
            url: process.env.API_URL + "/company/user/frontend/website/v1/list",
            controller: controller,
            data: data,
            success: (response) => {
                setCompanyUserCount(response.count);
                setCompanyUserList(response.data);
                setCompanyUserPageIndex(companyUserPageIndex);
                setCompanyUserPageSize(companyUserPageSize);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleDeleteCompanyUser = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: outletContext.intl.formatMessage({id: "global.deleteConfirmTitle"}),
            content: outletContext.intl.formatMessage({id: "global.deleteConfirmContent"}),
            okButtonProps: {
                status: "danger",
            },
            onOk: () => {
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/company/user/frontend/website/v1/delete",
                    controller: controller,
                    data: {
                        userIdList: selectedIdList,
                    },
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        setSelectedIdList([]);
                        setSelectedList([]);

                        handleLoadCompanyUserList(companyUserPageIndex, companyUserPageSize);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            },
        });
    };

    const handleExportCompanyUserSearch = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;

        Util.request(outletContext, {
            url: process.env.API_URL + "/company/user/frontend/website/v1/export/search",
            controller: controller,
            data: data,
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleExportCompanyUserSelected = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/company/user/frontend/website/v1/export/selected",
            controller: controller,
            data: {
                userIdList: selectedIdList,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessType(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <Card className="card" title={outletContext.intl.formatMessage({id: "global.searchTable"})} bordered={false}>
                        <Grid.Row gutter={20}>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "companyUser.userName"})} field="userName">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchCompanyUser();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "companyUser.userPhone"})} field="userPhone">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchCompanyUser();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "user.userStatus"})} field="userStatus">
                                    <Select
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseSelect"})}
                                        allowClear
                                        onChange={(event) => {
                                            handleSearchCompanyUser();
                                        }}
                                    >
                                        <Select.Option value={0}>在职</Select.Option>
                                        <Select.Option value={1}>离职</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Grid.Col>
                        </Grid.Row>
                        <Space className="action" wrap>
                            <Button
                                type="primary"
                                icon={<IconSearch />}
                                loading={isLoad}
                                onClick={() => {
                                    handleSearchCompanyUser();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.search"})}
                            </Button>
                            <Button
                                icon={<IconUndo />}
                                loading={isLoad}
                                onClick={() => {
                                    form.resetFields();

                                    setSelectedIdList([]);
                                    setSelectedList([]);
                                    setSortList([]);
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.reset"})}
                            </Button>
                            <Button
                                icon={<IconPlusCircle />}
                                onClick={() => {
                                    setIsDetail(true);
                                    setAction("save");
                                    setUserId("");
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.save"})}
                            </Button>
                            <Button
                                icon={<IconDelete />}
                                disabled={selectedIdList.length == 0}
                                onClick={() => {
                                    handleDeleteCompanyUser();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.delete"})}
                            </Button>
                        </Space>
                        <Sort sortList={sortList} setSortList={setSortList} />
                        <Table
                            className="table"
                            columns={columnList.concat([operationColumn])}
                            rowKey="userId"
                            data={companyUserList}
                            rowSelection={{
                                type: selectType,
                                columnWidth: 50,
                                selectedRowKeys: selectedIdList,
                                onChange: (selectedRowKeys) => {
                                    setSelectedIdList(Util.getSelectedIdList(selectedIdList, companyUserList, "userId", selectedRowKeys));
                                    setSelectedList(Util.getSelectedList(selectedList, companyUserList, "userId", selectedRowKeys));
                                },
                            }}
                            scroll={{x: 50 + columnList.length * 150 + operationColumn.width}}
                            border={false}
                            pagination={false}
                            onChange={(pagination, sorter, filters) => {
                                setSortList(Util.getSortList(sortList, sorter, columnList));
                            }}
                        />
                        <div className="pagination">
                            <div className="pagination_selected">
                                <Button
                                    onClick={() => {
                                        setIsSelected(true);
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.selected"})} {selectedIdList.length}
                                </Button>
                            </div>
                            {typeof companyUserCount == "undefined" ? null : (
                                <Pagination
                                    current={companyUserPageIndex}
                                    total={companyUserCount}
                                    simple={main.isMobile}
                                    showTotal
                                    sizeCanChange={!main.isMobile}
                                    onChange={(pageIndex, pageSize) => {
                                        handleLoadCompanyUserList(pageIndex, pageSize);
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                </Form>
                <div className="card_bottom"></div>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
            {isDetail ? (
                <CompanyUserDetail
                    visible={isDetail}
                    action={action}
                    userId={userId}
                    onClose={() => {
                        setIsDetail(false);
                    }}
                    onSubmit={() => {
                        setIsDetail(false);

                        handleLoadCompanyUserList(companyUserPageIndex, companyUserPageSize);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

CompanyUserIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(CompanyUserIndex);
